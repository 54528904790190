import { FORMAT_FILE_SIZE } from '@/utils'

export default {
  'address.add.label': 'Pridať adresu',
  'address.city.label': 'Mesto',
  'address.city.placeholder': 'Zadaj mesto',
  'address.country.label': 'Štát',
  'address.house-number.label': 'Číslo domu',
  'address.house-number.placeholder': 'Zadaj číslo domu',
  'address.label': 'Adresa',
  'address.street-name.label': 'Ulica',
  'address.street-name.placeholder': 'Zadaj ulicu',
  'address.zip-code.label': 'PSČ',
  'address.zip-code.placeholder': 'Zadaj smerovacie číslo',
  'addresses.label': 'Adresy',
  'auditable.tooltip.created': 'Vytvoril: {0}, {1}',
  'auditable.tooltip.updated': 'Vytvoril: {0}, {1}<br>Upravil: {2}, {3}',
  'bank-account.bank-id.label': 'Kód banky',
  'bank-account.bank-id.placeholder': 'Zadaj kód banky',
  'bank-account.bic.label': 'SWIFT/BIC',
  'bank-account.bic.placeholder': 'Zadaj SWIFT/BIC',
  'bank-account.create.label': 'Vytvoriť bankové spojenie',
  'bank-account.iban.label': 'IBAN',
  'bank-account.iban.placeholder': 'Zadaj IBAN',
  'bank-account.label': 'Bankový účet',
  'bank-account.label.short': 'Účet',
  'bank-account.name.label': 'Banka',
  'bank-account.name.placeholder': 'Zadaj názov banky',
  'bank-account.number.label': 'Číslo účtu',
  'bank-account.number.placeholder': 'Zadaj číslo účtu',
  'bank-account.update.title': 'Upraviť bankové spojenie',
  'bank-accounts.label': 'Bankové účty',
  'bank-tx-import.upload.success': 'Import platieb prebehol úspešne.',
  'cash-box.create.label': 'Vytvoriť kasu',
  'cash-box.edit.label': 'Upraviť kasu',
  'cash-box.label': 'Kasa',
  'cash-boxes.label': 'Kasy',
  'code-list.create.label': 'Vytvorenie položky',
  'code-list.edit.label': 'Editácia položky',
  'common.add': 'Pridať',
  'common.amount': 'Množstvo',
  'common.amount.money': 'Čiastka',
  'common.amount.placeholder': 'Zadaj sumu',
  'common.cancel': 'Zrušiť',
  'common.cash': 'Hotovosť',
  'common.category': 'Kategória',
  'common.category.multiple': 'Viac kategorií',
  'common.cheque': 'Šeky',
  'common.code': 'Kód',
  'common.color': 'Farba',
  'common.data-source': 'Zdroj dát',
  'common.data.detailed.summary': 'Detailný prehľad dát',
  'common.data.not-records': 'Žiadny záznam neexistuje.',
  'common.data.too-many-records': 'Príliš veľa dát, upresnite vyhľadávanie.',
  'common.date': 'Dátum',
  'common.date-time': 'Dátum a čas',
  'common.date.from': 'Dátum od',
  'common.date.to': 'Dátum do',
  'common.days': 'dní',
  'common.default': 'Predvolená',
  'common.delete': 'Zmazať',
  'common.delta': 'Zmena',
  'common.denied': 'Zakázané',
  'common.detail': 'Detail',
  'common.difference': 'Rozdiel',
  'common.edit': 'Editovať',
  'common.email': 'E-mail',
  'common.email.placeholder': 'Zadaj e-mail',
  'common.enabled': 'Povolená',
  'common.enabled.2': 'Povolené',
  'common.error': 'Chyba',
  'common.export': 'Exportovať',
  'common.fetch': 'Načítať',
  'common.file': 'Súbor',
  'common.filter': 'Filtrovať',
  'common.filter.cancel': 'Zrušiť filter',
  'common.firstname': 'Meno',
  'common.firstname.placeholder': 'Zadaj meno',
  'common.from': 'Od',
  'common.grouping': 'Zoskupenie',
  'common.inactive.label': 'Vyradené',
  'common.inactive.show': 'Zobraziť neaktívne',
  'common.income': 'Príjem',
  'common.income.type': 'Typ príjmu',
  'common.index': 'Index',
  'common.item': 'Položka',
  'common.item.inline': 'Položka:',
  'common.item.add': 'Pridať položku',
  'common.loading': 'Načítavanie dát',
  'common.month.current': 'Aktuálny mesiac',
  'common.month.previous': 'Minulý mesiac',
  'common.move.down': 'Presunúť dole',
  'common.move.up': 'Presunúť hore',
  'common.movement': 'Pohyb',
  'common.name': 'Názov',
  'common.name.placeholder': 'Zadaj názov',
  'common.no': 'Nie',
  'common.not-defined': 'Nezaradené',
  'common.not-valid': '!!! Neplatná položka',
  'common.note': 'Poznámka',
  'common.note.short': 'Pozn.',
  'common.number': 'Číslo',
  'common.options.empty': 'Nebola nájdená žiadna položka.',
  'common.outcome': 'Výdaj',
  'common.overview': 'Prehľad',
  'common.payment': 'Platba',
  'common.payment.card': 'Kartou',
  'common.payments.card': 'Platby kartou',
  'common.period': 'Od - Do',
  'common.price': 'Cena',
  'common.price.total': 'Cena celkom',
  'common.price.total.with.vat': 'Cena celkom s DPH',
  'common.price.total.without.vat': 'Cena celkom bez DPH',
  'common.price.unit': 'Cena / MJ',
  'common.price.without.vat': 'Celkom bez DPH',
  'common.price.without.vat.short': 'Σ bez DPH',
  'common.print': 'Tlačiť',
  'common.print.documents': 'Vytlačiť dokumenty',
  'common.print.setting': 'Nastavenie pred tlačou',
  'common.production': 'Výroba',
  'common.purchase': 'Nákup',
  'common.quantity': 'Počet',
  'common.range.label': 'Obdobie',
  'common.remove': 'Odstrániť',
  'common.sale': 'Predaj',
  'common.save': 'Uložiť',
  'common.search': 'Vyhľadaj',
  'common.search.label': 'Vyhľadať',
  'common.search.placeholder': 'Zadaj text',
  'common.show.all': 'Zobraziť všetky',
  'common.show.all.2': 'Zobraziť všetko',
  'common.show.details': 'Zobraziť detaily',
  'common.sign.date': '(dátum, podpis)',
  'common.status': 'Stav',
  'common.stop': 'Zastaviť',
  'common.subject': 'Subjekt',
  'common.subscriber': 'Odberateľ',
  'common.summary': 'Súhrn',
  'common.supplier': 'Dodávateľ',
  'common.surname': 'Priezvisko',
  'common.surname.placeholder': 'Zadaj priezvisko',
  'common.to': 'Do',
  'common.total': 'Celkom',
  'common.total.with.vat': 'Celkom s DPH',
  'common.total.with.vat.short': 'Σ s DPH',
  'common.transfer': 'Prevod',
  'common.type': 'Typ',
  'common.user.created': 'Vystavil',
  'common.valid-from.label': 'Počiatočný dátum',
  'common.value': 'Hodnota',
  'common.vat.share': 'Z toho DPH',
  'common.vat.total': 'Celkom DPH',
  'common.with.vat': 's DPH',
  'common.without.vat': 'bez DPH',
  'common.year': 'Rok',
  'common.yes': 'Áno',
  'company-branch.create.label': 'Vytvoriť prevádzku',
  'company-branch.edit.label': 'Upraviť prevádzku',
  'company-branch.label': 'Prevádzka',
  'company-branch.name.label': 'Názov',
  'company-branch.name.placeholder': 'Zadaj názov',
  'company-branches.label': 'Prevádzky',
  'company.address.label': '{0} (sídlo)',
  'company.address.label.short': 'Sídlo',
  'company.company-id.label': 'IČ',
  'company.company-id.placeholder': 'Zadaj IČ',
  'company.create.label': 'Vytvoriť firmu',
  'company.default-currency.label': 'Hlavná mena',
  'company.for-order.label': 'Používať adresu pre objednávky a dodávky',
  'company.for-order.info': 'Adresa pre objednávky a dodávky',
  'company.label': 'Firma',
  'company.sale-settings.delivery-note-enabled.label': 'Dodací list',
  'company.sale-settings.due-date-offset.label': 'Splatnosť faktúr',
  'company.sale-settings.edit.label': 'Upraviť nastavenie spolupráce',
  'company.sale-settings.payment-type.label': 'Objednávky - platby',
  'company.save.success': 'Firma bola úspešne uložená.',
  'company.vat-id-local.label': 'DIČ (SK)',
  'company.vat-id-local.placeholder': 'Zadaj DIČ (SK)',
  'company.vat-id.label': 'IČ-DPH',
  'company.vat-id.placeholder': 'Zadaj IČ-DPH',
  'company.vat-payer.label': 'Platca DPH',
  'company.vat-payer.not.label': 'Neplatca DPH',
  'company.web.label': 'Web',
  'company.web.placeholder': 'Zadaj web',
  'configuration.invoice.edit.label': 'Upraviť predvolené hodnoty',
  'configuration.invoice.label': 'Predvolené hodnoty',
  'configuration.invoice.logo.label': 'Logo',
  'configuration.invoice.stamp.label': 'Razítko',
  'configuration.invoice.vat.calculation-type.label': 'Výpočet DPH',
  'confirmation.button.yes': 'Potvrdiť',
  'confirmation.title': 'Upozornenie',
  'contact.add.label': 'Pridať kontakt',
  'contact.create.label': 'Vytvoriť kontakt',
  'contact.edit.label': 'Upraviť kontakt',
  'contact.label': 'Kontakt',
  'contact.name.label': 'Názov',
  'contact.name.placeholder': 'Zadaj názov kontaktu',
  'contact.phone-number.label': 'Telefónne číslo',
  'contact.phone-number.placeholder': 'Zadaj telefónne číslo',
  'contact.position.label': 'Pozícia',
  'contact.position.placeholder': 'Zadaj pozíciu',
  'contacts.label': 'Kontakty',
  'currencies.label': 'Povolené meny',
  'currency.label': 'Mena',
  'date.today': 'Dnes',
  'date.yesterday': 'Včera',
  'date.year.current': 'Tento rok',
  'date.year.last': 'Minulý rok',
  'date.month.current': 'Tento mesiac',
  'date.month.last': 'Minulý mesiac',
  'date.week.last': 'Minulý týždeň',
  'date.week.current': 'Tento týždeň',
  'delivery-note.label': 'Dodací list č. {0}',
  'delivery-note.label.short': 'Dodací list',
  'delivery-notes.label': 'Dodacie listy',
  'delivery-route.map.show': 'Zobraziť trasu na mape',
  'delivery-routes.label': 'Rozvozové trasy',
  'delivery-routes.region.label': 'Rozvozové trasy v regiónoch',
  'document.delete.confirmation': 'Naozaj chcete súbor odstániť?',
  'document.empty': 'Dokument nebol vybraný.',
  'document.empty.image': 'Obrázok nebol vybraný.',
  'document.label': 'Dokument',
  'document.upload.choose.file': 'Vyber alebo pretiahni súbor',
  'document.upload.drop-zone.label': 'Pusti súbor tu',
  'document.upload.load.file': 'Nahrať',
  'entity.delete.confirmation': 'Naozaj chcete odstrániť položku?',
  'entity.delete.success': 'Položka bola úspešne zmazaná.',
  'entity.edit.error': 'Položku sa nepodarilo zmeniť.',
  'entity.edit.success': 'Položka bola zmenená.',
  'error.auth.login': 'Neplatné prihlasovacie údaje.',
  'error.bank-money-movement.moneyBox.not.exist': 'Pre bankový účet s názvom "{0}" neexistuje pokladňa.',
  'error.bank-money-movement.remainingAmount.not-zero': 'Čiastka pohybu musí byť rozdelená celá.',
  'error.bank-tx-import.account.not.found.by.iban': 'Pre importované transakcie neexistuje bankový účet.',
  'error.bank-tx-import.catm.transactionId.unknown': 'Nie je možné rozpoznať ID transakcie.',
  'error.cash-box.accept.empty': 'Kasa musí prijímať aspon jeden spôsob platby.',
  'error.company.owned.missing': 'Nastavenie firmy nie je dokončené.',
  'error.data.integrity.violation.duplicate': 'Akciu nie je možné vykonať. V aplikácii už existuje záznam s rovnakou hodnotou.',
  'error.data.integrity.violation.referenced': 'Zmenu nie je možné uložiť. Zmenený záznam je v systéme naviazaný v iných záznamoch.',
  'error.data.invalid': 'Neplatné dáta.',
  'error.inventory.date.invalid': 'Neplatný dátum. Už existuje skladový pohyb s novším dátumom.',
  'error.inventory.invalid.item.price': 'Niektoré položky nemajú pre dané obdobie platný cenník.',
  'error.inventory.items.emtpy': 'Inventúra neobsahuje žiadne položky.',
  'error.invoice.dueDate.before.dateOfIssue': 'Dátum splatnosti nesmie byť pred dátumom vystavenia.',
  'error.invoice.document.multiple.size.overflow': 'Maximálny počet hromadného sťahovania faktúr je {0}.',
  'error.invoice.pay.amount.low': 'Suma pohybu nesmie byť vyššia ako nezaplatený zostatok na faktúre.',
  'error.item.dispatch.item.invalid': 'Neplatná položka.',
  'error.item.update.dispatch.item': 'Položku nie je možné označiť za skladovú, pretože obsahuje nastavenie položiek vyskladňovaných pri predaji.',
  'error.item.update.stock-item.active': 'Označenie položky za skladovú nie je možné zrušiť. Položku musíte najskôr na skladoch deaktivovať.',
  'error.item.update.dispatch.subitem': 'Položku nie je možné označiť za neskladovú, pretože je zahrnutá ako vyskladňovaná položka pri predaji u inej neskladovej položky.',
  'error.money-movement.type.not.allowed': 'Neplatný typ faktúry.',
  'error.not.allowed': 'Neoprávnený prístup.',
  'error.order-statement.currency.multiple': 'Vyúčtovanie nesmie obsahovať objednávky roznych mien.',
  'error.order-statement.not.delivered.order': 'Vyúčtovanie nesmie obsahovať nedodanú objednávku.',
  'error.order-statement.orders.empty': 'Vyúčtovanie musí obsahovať aspoň jednu objednávku',
  'error.order.item.price.required': 'Niektoré z položiek nemajú platný cenník.',
  'error.original-password.invalid': 'Nesprávne aktuálne heslo.',
  'error.price-list.item.invalid': 'Cenník nie je možné pre katalogovú položku editovať.',
  'error.priceList.currency.duplicate': 'Cenník obsahuje pre niektorú menu duplicitné záznamy.',
  'error.range.too.big': 'Maximálny rozsah dátumu je 1 rok.',
  'error.recipe.delete.items.not.empty': 'Najskôr musíte zmazať všetky položky receptúry.',
  'error.record.notFound': 'Záznam sa nenašiel.',
  'error.stock.item.update.item.invalid': 'Neplatná položka',
  'error.stock.movement.item.never.income': 'Položka "{0}" nebola nikdy naskladnená.',
  'error.stock.movement.item.not.in.stock': 'Položka "{0}" nie je pridená na sklad alebo je vyradená.',
  'error.stock.movement.recipe.not.valid.item': 'Položku "{0}" z vybranej receptúry nie je možné vyskladniť. Najskôr musíte túto položku povoliť pre cieľový sklad.',
  'error.stock.movement.transfer.not.valid.item': 'Položku "{0}" z vybraného výdaju nie je možné naskladniť. Najskôr musíte túto položku povoliť pre cieľový sklad.',
  'error.stock.movement.update.not.last': 'Je možné meniť len posledný pohyb.',
  'error.stock.record.date.invalid': 'Neplatný dátum. Už existuje skladový pohyb s novším dátumom.',
  'error.takings.update.totalAmountCash.min': 'Hodnota celkovej hotovosti nesmie byť menšia než hodnota hotovosti.',
  'error.upload.denied': 'Súbor sa nepodarilo nahrať na server.',
  'error.upload.extension': 'Nepodporovaný typ súboru. Podporované typy sú {0}.',
  'error.upload.network': 'Súbor sa nepodarilo nahrať na server.',
  'error.upload.server': 'Súbor sa nepodarilo nahrať na server.',
  'error.upload.size': 'Príliš velký súbor. Maximálna veľkosť súboru je {0}.',
  'error.user.not.worker': 'Prihlásený užívateľ nie je pracovník.',
  'error.work-record.date.invalid': 'Neplatný datum od - do.',
  'error.work-record.date.overlap': 'Záznam sa prekrýva s iným záznamom.',
  'error.work-record.timesheet.locked': 'Výkaz práce je pre toto obdobie uzamknutý.',
  'finance.accounting-type.label': 'Účtovanie DPH',
  'finance.amount.card': 'Fiškálny príjem - kartou',
  'finance.amount.cash': 'Fiškálny príjem - hotovosť',
  'finance.amount.cash.non.fiscal': 'Nefiškálny príjem - hotovosť',
  'finance.amount.cheque': 'Fiškálny príjem - šeky',
  'finance.amount.cheque.non.fiscal': 'Nefiškálny príjem - šeky',
  'finance.cash-flow.label': 'CashFlow',
  'finance.cash-flow.status.label': 'Stav cashflow',
  'finance.fiscal.label': 'Fiškálny',
  'finance.income.fiscal.label': 'Fiškálny príjem',
  'finance.income.label': 'Príjem',
  'finance.income.non-fiscal.label': 'Nefiškálny príjem',
  'finance.income.vat': 'Príjmy DPH',
  'finance.income.without.vat': 'Príjmy bez DPH',
  'finance.incomes.label': 'Príjmy',
  'finance.label': 'Financie',
  'finance.non-fiscal.label': 'Nefiškálny',
  'finance.outcome.label': 'Výdaj',
  'finance.outcome.vat': 'Výdaje DPH',
  'finance.outcome.without.vat': 'Výdaje bez DPH',
  'finance.outcomes.label': 'Výdaje',
  'finance.overview.label': 'Prehľad financií',
  'finance.price.with.vat': 'Cena s DPH',
  'finance.tax.base.label': 'Základ dane',
  'finance.tax.base.label.detailed': 'Základ dane z príjmu',
  'finance.tax.base.label.short': 'Základ',
  'finance.taxable-date.label.short': 'DUZP',
  'finance.vat-rate.label': 'Sadzba DPH',
  'finance.vat-rate.label.short': 'Sadzba',
  'finance.vat.income.label': 'Prijatá DPH',
  'finance.vat.label': 'DPH',
  'finance.vat.outcome.label': 'Vydaná DPH',
  'finance.vat.placeholder': 'Zadaj DPH',
  'footer.company': 'SmartBrains',
  'footer.copyright.label': 'Copyright © 2020',
  'group-type.month.label': 'Mesiace',
  'group-type.week.label': 'Týždne',
  'group-type.day.label': 'Dni',
  'inventory.categories.label': 'Len vybrané kategórie',
  'inventory.create': 'Vytvoriť inventúru',
  'inventory.create.label': 'Nová inventúra',
  'inventory.delete.confirmation': 'Chcete naozaj zmazať túto inventúru?',
  'inventory.delete.success': 'Inventúra bola úspešne zmazaná.',
  'inventory.income-movement.amount.label': 'Hodnota prebytku celkom',
  'inventory.items.label': 'Inventúrny stav položiek',
  'inventory.label': 'Inventúra č. {0}',
  'inventory.label.short': 'Inventúra',
  'inventory.movements.summary.label': 'Vyrovnanie stavu skladu',
  'inventory.outcome-movement.amount.label': 'Hodnota manka celkom',
  'inventory.status.label': 'Inventúrny stav',
  'invoice-items.label': 'Položky faktúry',
  'invoice-series.create.label': 'Vytvoriť číselnú radu',
  'invoice-series.edit.label': 'Upraviť číselnú radu',
  'invoice-series.example.label': 'Ukážka čísla faktúry / VS',
  'invoice-series.format.label': 'Formát',
  'invoice-series.index.label': 'Aktuálny index',
  'invoice-series.index.placeholder': 'Index',
  'invoice-series.label': 'Číselná rada faktúry',
  'invoice-series.label.plural': 'Číselné rady',
  'invoice-series.prefix.label': 'Prefix',
  'invoice-series.prefix.placeholder': 'Zadaj prefix',
  'invoice.add': 'Pridať faktúru',
  'invoice.constant-symbol.label': 'Konštantný symbol',
  'invoice.constant-symbol.label.short': 'Konštantný s.',
  'invoice.create.label': 'Nová faktúra',
  'invoice.data.label': 'Ďalšie nastavenia',
  'invoice.date-of-issue.label': 'Dátum vystavenia',
  'invoice.delete.confirmation': 'Chcete naozaj zmazať túto faktúru?',
  'invoice.delete.success': 'Faktúra bola úspešne zmazaná.',
  'invoice.detail.label': 'Detail faktúry',
  'invoice.document.multiple.name.prefix': 'Faktúry',
  'invoice.document.name.prefix': 'Faktúra',
  'invoice.due-date.label': 'Dátum splatnosti',
  'invoice.due.date.days.label': 'Splatnosť (dni)',
  'invoice.due.date.label': 'Splatnosť',
  'invoice.due.date.mean': 'Priemerná splatnosť',
  'invoice.edit.label': 'Úprava faktúry',
  'invoice.income.label': 'Prijatá faktúra č. {0}',
  'invoice.label': 'Faktúra',
  'invoice.label.description': 'Suma: {0}, splatnosť: {1}',
  'invoice.label.generic': 'Doklad',
  'invoice.label.with.number': 'Faktúra {0}',
  'invoice.number.label': 'Číslo faktúry',
  'invoice.outcome.label': 'Faktúra č. {0}',
  'invoice.overdue.label': 'Po splatnosti',
  'invoice.paid': 'Uhradená',
  'invoice.partially-paid': 'Čiastočne uhradená',
  'invoice.payment.label': 'Úhrady faktúry',
  'invoice.reference.label': 'Referencia (napr. objednávka)',
  'invoice.reference.label.short': 'Referencia',
  'invoice.specific-symbol.label': 'Špecifický symbol',
  'invoice.specific-symbol.label.short': 'Špecifický s.',
  'invoice.taxable-date.label': 'Dátum zdan. plnenia',
  'invoice.total-price.label': 'Celkom na úhradu',
  'invoice.transferred-vat.amount.label': 'Vyčíslenie DPH',
  'invoice.transferred-vat.label': 'Prenos daňovej povinnosti',
  'invoice.unpaid.amount.label': 'Neuhradené',
  'invoice.variable-symbol.label': 'Variabilný symbol',
  'invoice.variable-symbol.label.short': 'Variabilný s.',
  'invoices.income.label': 'Prijaté faktúry',
  'invoices.label': 'Faktúry',
  'invoices.newest.label': 'Najnovšie FA',
  'invoices.outcome.label': 'Vystavené faktúry',
  'invoices.overview.label': 'Prehľad fakturácie',
  'invoicing.label': 'Fakturácia',
  'item-batch.label': 'Šarža',
  'item-batch.name.label': 'Číslo šarže',
  'item-batches.label': 'Šarže položky',
  'item-batches.placeholder': 'Šarže položky',
  'item-categories.label': 'Kategórie položiek',
  'item.batch-evidence.label': 'Evidovať šarže',
  'item.card.label': 'Karta položky katalógu',
  'item.create.label': 'Vytvoriť katalógovú položku',
  'item.description.label': 'Názov pre tlač',
  'item.dispatch.amount.label': 'Vyskladňované množstvo na MJ',
  'item.dispatch.create.label': 'Pridať vyskladňovanú položku',
  'item.dispatch.edit.label': 'Upraviť vyskladňovanú položku',
  'item.dispatch.label': 'Položky vyskladňované pri predaji',
  'item.edit.label': 'Upraviť katalógovú položku',
  'item.filter.label': 'Filter položiek',
  'item.for-purchase.label': 'Na predaj',
  'item.for-purchase.unused': 'Položka nemá platný cenník',
  'item.for-stock.label': 'Skladová položka',
  'item.for-stock.label.short': 'Skladová',
  'item.for-stock.unused': 'Položka nie priradená na žiadny sklad',
  'item.origin.label': 'Pôvod',
  'item.price-list.none': 'Položka je na predaj, ale nemá žiaden platný cenník. Pridať cenník môžete na záložke Predaj.',
  'item.scale.label': 'Počet desatinných miest',
  'item.scale.zero.label': 'Nedeliteľná',
  'item.stock-item.none': 'Položka je skladová, ale nie je priradená na žiaden sklad. Priradiť ju môžete na záložke Sklad.',
  'item.type.label': 'Typ položky',
  'items.label': 'Katalóg položiek',
  'login.submit': 'Prihlásiť',
  'money-box.balance.delta.label': 'Zmena zostatku za obdobie',
  'money-box.balance.label': 'Aktuálny zostatok',
  'money-box.create.label': 'Vytvoriť pokladňu alebo účet',
  'money-box.edit.label': 'Upraviť pokladňu alebo účet',
  'money-box.initial-amount.label': 'Počiatočný stav účtu',
  'money-box.label': 'Pokladňa',
  'money-boxes.label': 'Pokladne a účty',
  'money-boxes.label.short': 'Pokladne',
  'money-boxes.status.label': 'Stav pokladní',
  'money-movement-categories.income.label': 'Príjmové kategórie pohybov',
  'money-movement-categories.outcome.label': 'Výdajové kategórie pohybov',
  'money-movement.add': 'Pridať pohyb',
  'money-movement.amount.tooltip': '<div><span class="float-left">Hotovosť:</span><span class="ml-2 float-right">{0}</span></div><div class="clearfix"><span class="float-left">Šeky:</span><span class="ml-2 float-right">{1}</span></div>',
  'money-movement.categories.label': 'Kategórie',
  'money-movement.category.UNCATEGORIZED': 'Nezaradené',
  'money-movement.category.label': 'Kategória',
  'money-movement.create.label': 'Vytvoriť pohyb',
  'money-movement.edit.label': 'Upraviť pohyb',
  'money-movement.filter.label': 'Filter pohybov',
  'money-movement.import.label': 'Import bankových výpisov',
  'money-movement.import.label.short': 'Import pohybov',
  'money-movement.import.number': 'Počet importovaných/ignorovaných',
  'money-movement.split.label': 'Rozdeliť pohyb',
  'money-movement.split.note': 'Rozdelený pohyb',
  'money-movement.split.remaining.label': 'Zostáva rozdeliť',
  'money-movement.split.success': 'Pohyb bol úspešne rozdelený.',
  'money-movement.type.label': 'Typ pohybu',
  'money-movements.label': 'Pohyby',
  'money-movements.summary.by.category.label': 'Súhrn po kategóriách',
  'money-movements.summary.label': 'Pohyby na pokladniach a účtoch',
  'money-movements.total.fiscal.label': 'Celkom - fiškálne',
  'money-movements.total.not.fiscal.label': 'Celkom - nefiškálne',
  'navbar.logout': 'Odhlásiť',
  'navbar.password.change': 'Zmeniť heslo',
  'order-item-template.create.label': 'Pridať položku šablóny objednávky',
  'order-item-template.edit.label': 'Upraviť položku šablóny objednávky',
  'order-item-template.label': 'Šablóna pravidelnej objednávky',
  'order-statement.create.label': 'Vytvoriť vyúčtovanie',
  'order-statement.date.label': 'Dátum vyúčtovania',
  'order-statement.delete.confirmation': 'Naozaj chcete odstrániť vyúčtovanie "{0}"?',
  'order-statement.delete.success': 'Vyúčtovanie bolo úspešne zmazané.',
  'order-statement.detail.label': 'Detail vyúčtovania',
  'order-statement.label': 'Vyúčtovanie dodávok',
  'order-statement.label.short': 'Vyúčtovanie',
  'order-statement.label.with.number': 'Vyúčtovanie č. {0}',
  'order-statement.list.filter.empty': 'Pre zobrazenie objednávok musí byť vybraný odberateľ a mena.',
  'order-statement.new.label': 'Nové vyúčtovanie',
  'order-statement.orders.label': 'Dodávky zahrnuté do vyúčtovania',
  'order-statement.total.invoiced.label': 'Fakturované',
  'order-statement.total.price.label': 'Hodnota celkom',
  'order-statements.label.short': 'Vyúčtovania',
  'order-statements.overview.label': 'Prehľad vyúčtovaní',
  'order.create.label': 'Vytvoriť objednávku',
  'order.delete.confirmation': 'Chcete naozaj zmazať objednávku?',
  'order.delete.success': 'Objednávka bola úspešne zmazaná.',
  'order.deliver.all.confirmation': 'Naozaj chcete označiť všetky objednávky za dodané v plnej hodnote objednávky?',
  'order.delivered': 'Dodaná',
  'order.delivered.all': 'Všetko dodané',
  'order.delivered.date.label': 'Dátum dodania',
  'order.delivered.label': 'Dodávka č. {0}',
  'order.deliveries.label': 'Zoznam dodávok',
  'order.delivery.schedule.label': 'Rozpis dodávok',
  'order.delivery.status.label': 'Stav dodávky',
  'order.item.label': 'Položka',
  'order.items.label': 'Položky objednávky',
  'order.label': 'Objednávka',
  'order.label.short': 'Obj.č.',
  'order.label.with.number': 'Objednávka č. {0}',
  'order.list.filter.empty': 'Pre zobrazenie objednávok musí byť vybraný dátum a prevádzka.',
  'order.new.label': 'Nová objednávka',
  'order.not-billed.number': 'Nevyúčtované: {0}',
  'order.ordered.label': 'Objednané',
  'order.returned.label': 'Vrátené',
  'order.statements.not.billed.label': 'Nevyúčtované objednávky',
  'order.subscriber-order-number.label': 'Číslo objednávky partnera',
  'order.total-without-vat.label': 'Hodnota',
  'order.undelivered.number': 'Počet nedodaných: {0}',
  'orders.delivered': 'Dodané',
  'orders.delivered.label': 'Dodávky',
  'orders.delivered.overview.label': 'Prehľad dodávok',
  'orders.delivered.summary.by.product.label': 'Súhrn dodávok - produkty',
  'orders.deliveries.label': 'Objednávky a dodávky',
  'orders.label': 'Objednávky',
  'orders.period.label': 'Obdobie objednávok',
  'orders.summary.by.item.label': 'Súhrn po položkách',
  'orders.summary.item.label': 'Súhrn dodávok - položky',
  'orders.summary.partner.label': 'Súhrn po partneroch',
  'orders.summary.payment.label': 'Súhrn dodávok - platba',
  'orders.summary.period.label': 'Súhrn dodávok za obdobie',
  'orders.summary.returned.label': 'Súhrn vrátené po položkách',
  'orders.total.delivered.label': 'Počet dodávok',
  'partner.address.regions.label': 'Regióny adries partnerov',
  'partner.categories.label': 'Kategórie partnerov',
  'partner.category.label': 'Kategória partnera',
  'partner.contacts.label': 'Kontakty partnerov',
  'company.edit.label': 'Upraviť firmu',
  'partner.label': 'Partner',
  'partner.profile.label': 'Profil partnera',
  'partners.label': 'Partneri',
  'payment-method.label': 'Spôsob platby',
  'payment-method.unknown': 'Bez typu platby',
  'payment-type.statement-enabled.label': 'Vyúčtovanie a fakturácia',
  'payment-types.label': 'Typ platby od partnerov',
  'price-list.create.label': 'Vytvoriť cenník',
  'price-list.edit.label': 'Upraviť cenník',
  'price-list.individual.label': 'Individuálny cenník',
  'price-list.item.label': 'Produkt',
  'price-list.label': 'Od {0}',
  'recipe-item.create.label': 'Pridať položku receptúry',
  'recipe-item.edit.label': 'Upraviť položku receptúry',
  'recipe-item.label': 'Výrobná položka',
  'recipe-items.label': 'Položky receptúry',
  'recipe.amount.label': 'Množstvo na výrobnú dávku',
  'recipe.batch-size.label': 'Veľkosť výrobnej dávky',
  'recipe.edit.label': 'Upraviť výrobnú dávku',
  'record.create.label': 'Vytvoriť záznam',
  'record.delete.confirmation': 'Naozaj chcete odstrániť záznam?',
  'record.delete.success': 'Záznam bol úspešne zmazaný.',
  'record.label': 'Záznam',
  'record.update.label': 'Upraviť záznam',
  'records.label': 'Záznamy',
  'records.overview.label': 'Prehľad záznamov',
  'region.label': 'Región',
  'region.unknown': 'Bez zaradenia',
  'regions.label': 'Regióny',
  'regular-sale.label': 'Pravidelný predaj',
  'regular-sale.orders.label': 'Denné objednávky',
  'setting.company.label': 'Nastavenie firmy',
  'setting.finance.label': 'Nastavenie financií',
  'setting.invoice.label': 'Nastavenie fakturácie',
  'setting.label': 'Nastavenie',
  'setting.permission.label': 'Nastavenie práv užívateľa',
  'setting.regular-sale.label': 'Nastavenie pravidelného predaja',
  'setting.sale.label': 'Nastavenie predaja',
  'setting.stock.label': 'Nastavenie skladov a položiek',
  'setting.worker.label': 'Nastavenie pracovníkov',
  'setting.working-activity.label': 'Nastavenie pracovných činností',
  'settings.label': 'Nastavenia',
  'stock-item.amount.label': 'Stav položky na skladoch',
  'stock-item.amount.label.short': 'Zásoba',
  'stock-item.card.label': 'Skladová karta položky',
  'stock-item.delta.label': 'Zmena stavu zásob',
  'stock-item.min-amount.detailed.label': 'Minimálna zásoba: {0} {1}',
  'stock-item.min-amount.label': 'Minimálna zásoba',
  'stock-item.min-amount.label.short': 'Min. zásoba',
  'stock-item.missing.label': 'Chýbajúca zásoba',
  'stock-item.status.label': 'Aktuálny stav na sklade',
  'stock-items.label': 'Stav zásob',
  'stock-items.under.limit.label': 'Chýbajúca zásoba položiek',
  'stock-movement.create.label': 'Pridať položku na sklad',
  'stock-movement.date.label': 'Dátum vystavenia',
  'stock-movement.edit.label': 'Upraviť položku na sklade',
  'stock-movement.filter.label': 'Filter pohybov',
  'stock-movement.income.amount.label': 'Hodnota príjmu',
  'stock-movement.income.create': 'Vytvoriť príjem',
  'stock-movement.income.delete.confirmation': 'Chcete naozaj zmazať tento príjem na sklad?',
  'stock-movement.income.delete.success': 'Príjem na sklad bol úspešne zmazaný.',
  'stock-movement.income.items.label': 'Položky príjmu',
  'stock-movement.income.label': 'Príjem na sklad č. {0}',
  'stock-movement.income.label.short': 'Príjem na sklad',
  'stock-movement.income.label.without.number': 'Príjem na sklad č.',
  'stock-movement.income.new.label': 'Nový príjem na sklad',
  'stock-movement.income.summary.label': 'Súhrn príjmu na sklad',
  'stock-movement.origin-stock.label': 'Pôvodný sklad',
  'stock-movement.outcome.amount.label': 'Hodnota výdaja',
  'stock-movement.outcome.create': 'Vytvoriť výdaj',
  'stock-movement.outcome.delete.confirmation': 'Chcete naozaj zmazať tento výdaj zo skladu?',
  'stock-movement.outcome.delete.success': 'Výdaj zo skladu bol úspešne zmazaný.',
  'stock-movement.outcome.items.add.by.recipe': 'Pridať položky podľa výrobnej receptúry',
  'stock-movement.outcome.items.label': 'Položky výdaja',
  'stock-movement.outcome.label': 'Výdaj zo skladu č. {0}',
  'stock-movement.outcome.label.short': 'Výdaj zo skladu',
  'stock-movement.outcome.label.without.number': 'Výdaj zo skladu č.',
  'stock-movement.outcome.new.label': 'Nový výdaj zo skladu',
  'stock-movement.outcome.note.delivery': 'Dodávka dňa {0}.',
  'stock-movement.outcome.summary.label': 'Súhrn výdaja zo skladu',
  'stock-movement.outcome.type.label': 'Typ výdaja',
  'stock-movement.target-stock.label': 'Cieľový sklad',
  'stock-movement.transfer.label': 'Preskladnenie',
  'stock-movements.label': 'Pohyby na sklade',
  'stock-movements.label.2': 'Skladové pohyby',
  'stock.create.label': 'Vytvoriť sklad',
  'stock.currency.label': 'Mena pohybov',
  'stock.edit.label': 'Upraviť sklad',
  'stock.expedition.update.success': 'Výdajový sklad bol zmenený.',
  'stock.for-expedition.label': 'Výdajový sklad pre dodávky',
  'stock.for-expedition.label.short': 'Výdajový sklad',
  'stock.for-expedition.setting.label': 'Nastavenie výdajového skladu pre dodávky',
  'stock.label': 'Sklad',
  'stock.overview.label': 'Prehľad skladov',
  'stocks.label': 'Sklady',
  'takings.create.label': 'Vytvoriť tržbu',
  'takings.daily.label': 'Denná tržba kasy',
  'takings.daily.label.short': 'Denná tržba',
  'takings.edit.label': 'Upraviť tržbu',
  'takings.include.non-fiscal': 'vrátane nefiškálnych príjmov',
  'takings.overview.label': 'Prehľad tržieb',
  'takings.total.daily.label': 'Denná tržba celkom',
  'takings.total.fiscal.label': 'Uzávierka celkom',
  'takings.total.fiscal.without.card.label': 'Hotové spolu',
  'takings.total.in.cash-box.label': 'Tržba v kase celkom',
  'takings.total.label': 'Tržba celkom',
  'takings.total.without.card.label': 'Tržba v kase',
  'takings.transfer': 'Prevod tržby na pokladňu',
  'takings.transfer.money-box.label': 'Cieľová hotovostná pokladňa',
  'takings.transfer.success': 'Tržba bola prevedená na pokladňu "{0}".',
  'takings.transfer.total.label': 'Prevod celkom',
  'timesheet.lock': 'Uzamknúť výkaz',
  'timesheet.locked': 'Výkaz je uzamknutý',
  'timesheet.unlock': 'Odomknúť výkaz',
  'timesheet.unlocked': 'Výkaz je odomknutý',
  'unit.label': 'MJ',
  'units.label': 'Merné jednotky',
  'user.create.label': 'Vytvoriť užívateľa',
  'user.edit.label': 'Upraviť užívateľa',
  'user.label': 'Užívateľ',
  'user.last-logged-in.label': 'Posledné prihlásenie',
  'user.password.change.success': 'Heslo bolo zmenené.',
  'user.password.change.title': 'Zmena hesla',
  'user.password.confirm.label': 'Nové heslo pre kontrolu',
  'user.password.confirm.placeholder': 'Zadaj nové heslo znova',
  'user.password.current.label': 'Aktuálne heslo',
  'user.password.current.placeholder': 'Zadaj aktuálne heslo',
  'user.password.label': 'Heslo',
  'user.password.new.label': 'Nové heslo',
  'user.password.new.placeholder': 'Zadaj nové heslo',
  'user.password.placeholder': 'Zadaj svoje heslo',
  'user.password.placeholder.short': 'Zadaj heslo',
  'user.password.set': 'Nastaviť heslo',
  'user.username.email.label': 'E-mail / login',
  'user.username.label': 'Prihlasovacie meno',
  'user.username.placeholder': 'Zadaj svoje prihlasovacie meno',
  'users.label': 'Užívatelia a práva',
  'value.accounting-type.label.FISCAL': 'Fiškálny',
  'value.accounting-type.label.INTERNAL': 'Prevod',
  'value.accounting-type.label.NON_FISCAL': 'Nefiškálny',
  'value.boolean-type.label.NO': 'Nie',
  'value.boolean-type.label.YES': 'Áno',
  'value.country.label.CZE': 'Česká republika',
  'value.country.label.SVK': 'Slovenská republika',
  'value.currency.description.CZK': 'Česká koruna [Kč]',
  'value.currency.description.EUR': 'Euro [€]',
  'value.currency.label.CZK': 'Kč',
  'value.currency.label.EUR': '€',
  'value.income-stock-movement-type.label.INVENTORY_SURPLUS': 'Inventúrny prebytok',
  'value.income-stock-movement-type.label.PRODUCTION': 'Výroba',
  'value.income-stock-movement-type.label.PURCHASE': 'Nákup',
  'value.income-stock-movement-type.label.TRANSFER': 'Preskladnenie',
  'value.invoice-series-format.label.PREFIX_YYYY_NNNN': 'PREFIX + ROK + INDEX',
  'value.invoice-series-format.label.PREFIX_NNNN_YYYY': 'PREFIX + INDEX + ROK',
  'value.invoice-status.label.OUTSTANDING': 'Neuhradené',
  'value.invoice-status.label.OVERDUE': 'Po splatnosti',
  'value.invoice-status.label.PAID': 'Uhradené',
  'value.item-determination-type.label.SALE': 'Na predaj',
  'value.item-determination-type.label.STOCK': 'Skladová',
  'value.item-origin-type.label.PRODUCTION': 'Výroba',
  'value.item-origin-type.label.PURCHASE': 'Nákup',
  'value.item-type.label.PRODUCT': 'Hmotná',
  'value.item-type.label.SERVICE': 'Služba',
  'value.money-box-type.label.BANK': 'Účet v banke',
  'value.money-box-type.label.CASH': 'Hotovostná pokladňa',
  'value.money-movement-type.label.INCOME': 'Príjem',
  'value.money-movement-type.label.OUTCOME': 'Výdaj',
  'value.outcome-stock-movement-type.label.INVENTORY_SHORTAGE': 'Inventúrne manko',
  'value.outcome-stock-movement-type.label.PRODUCTION': 'Výroba',
  'value.outcome-stock-movement-type.label.SALE': 'Predaj',
  'value.outcome-stock-movement-type.label.TRANSFER': 'Preskladnenie',
  'value.outcome-stock-movement-type.label.USAGE': 'Bežná spotreba',
  'value.outcome-stock-movement-type.label.WASTE': 'Odpad',
  'value.payment-method.label.BANK_TRANSFER': 'Bankovým prevodom',
  'value.payment-method.label.CASH': 'V hotovosti',
  'value.payment-method.label.CASH_ON_DELIVERY': 'Dobierka',
  'value.payment-method.label.CREDIT_CARD': 'Kartou',
  'value.permission-type.label.COMPANY_PARTNER': 'Partneri',
  'value.permission-type.label.FINANCE_MONEY_BOX': 'Pokladne',
  'value.permission-type.label.FINANCE_MOVEMENT_IMPORT': 'Import pohybov',
  'value.permission-type.label.FINANCE_OVERVIEW': 'Prehľad',
  'value.permission-type.label.INVOICE': 'Faktúry',
  'value.permission-type.label.REGULAR_SALE': 'Denné objednávky',
  'value.permission-type.label.SETTING_COMPANY': 'Firma',
  'value.permission-type.label.SETTING_FINANCE': 'Financie',
  'value.permission-type.label.SETTING_INVOICE': 'Fakturácia',
  'value.permission-type.label.SETTING_ITEM': 'Katalóg položiek',
  'value.permission-type.label.SETTING_REGULAR_SALE': 'Pravidelný predaj',
  'value.permission-type.label.SETTING_SALE': 'Predaj',
  'value.permission-type.label.SETTING_STOCK': 'Sklady',
  'value.permission-type.label.SETTING_USER': 'Užívatelia a práva',
  'value.permission-type.label.SETTING_WORKER': 'Pracovníci a pracovné činnosti',
  'value.permission-type.label.STOCK': 'Stav zásob a pohyby',
  'value.permission-type.label.TAKINGS': 'Zadanie tržby kasy',
  'value.permission-type.label.TAKINGS_OVERVIEW': 'Prehľad tržieb',
  'value.permission-type.label.TAKINGS_TRANSFER': 'Prevod tržby na pokladňu',
  'value.permission-type.label.WORK_RECORD_MANAGEMENT': 'Správa výkazov práce',
  'value.permission-type.label.WORK_RECORD_PERSONAL': 'Môj výkaz práce',
  'value.record-type.label.COMMENT': 'Poznámka',
  'value.record-type.label.EMAIL_INCOME': 'Pošta - prijatá',
  'value.record-type.label.EMAIL_OUTCOME': 'Pošta - odoslaná',
  'value.record-type.label.PERSONAL_MEETING': 'Osobné stretnutie',
  'value.record-type.label.PHONE_COMMUNICATION': 'Telefonát',
  'value.user-type.label.USER': 'Užívateľ',
  'value.user-type.label.WORKER': 'Pracovník',
  'value.vat-calculation.label.SUM': 'Z celkovej sumy',
  'value.vat-calculation.label.ITEM': 'Po položkách',
  'work-record.create.label': 'Vytvoriť výkaz práce',
  'work-record.duration.label': 'Celkom vykázané',
  'work-record.edit.label': 'Upraviť výkaz práce',
  'work-record.label': 'Výkaz práce',
  'work-record.overview.label': 'Prehľad výkazu práce',
  'work-record.overview.records.label': 'Prehľad záznamov',
  'work-record.personal.label': 'Môj prehľad výkazu práce',
  'work-record.personal.label.short': 'Môj výkaz práce',
  'work-record.price.label': 'Cena práce',
  'work-record.price.total.label': 'Cena práce celkom',
  'work-record.summary.period.label': 'Súhrn za obdobie',
  'work-record.summary.period.label.short': 'Súhrn za mesiac',
  'work-record.summary.worker.label': 'Výkaz pracovníkov',
  'worker.add': 'Pridať pracovníka',
  'worker.contract.label': 'Zmluvný vzťah',
  'worker.contract.placeholder': 'Zadaj zmluvný vzťah',
  'worker.edit.label': 'Úprava pracovníka',
  'worker.label': 'Pracovník',
  'worker.new.label': 'Nový pracovník',
  'worker.user.label': 'Je užívateľom',
  'workers.label': 'Pracovníci',
  'working-activities.label': 'Pracovné činnosti',
  'working-activity.add': 'Pridať činnosť',
  'working-activity.create.label': 'Nová pracovná činnosť',
  'working-activity.edit.label': 'Úprava pracovnej činnosti',
  'working-activity.label': 'Pracovná činnosť',
  'working-activity.new.label': 'Nová činnosť',
  'working-activity.price.label': 'Cena / hod.'
}

export const validations = {
  alpha: (field: string) => 'Pole môže obsahovať len písmená.',
  alpha_dash: (field: string) => 'Pole môže obsahovať len písmená, číslice, bodky a podčiarknutie.',
  alpha_num: (field: string) => 'Pole môže obsahovať len písmená a číslice.',
  alpha_spaces: (field: string) => 'Pole môže obsahovať len písmená, číslice a medzery.',
  between: (field: string, { min, max }: { min: number, max: number }) => `Hodnota pola musí byť medzi ${min} a ${max}.`,
  confirmed: (field: string) => 'Hodnota položky nie je rovnaká.',
  decimal: (field: string, { decimals }: { decimals: number }) => `Hodnota pola musí byť číselná a smie obsahovať ${decimals} desatinné miesta.`,
  digits: (field: string, { length }: { length: number }) => `Pole musí obsahovať ${length} ${length < 5 ? 'čísla' : 'čísiel'}.`,
  dimensions: (field: string, { width, height }: {width: number, height: number}) => `Obrázok musí mať ${width} x ${height} pixlov.`,
  email: (field: string) => 'Pole musí obsahovať správnu emailovú adresu.',
  excluded: (field: string) => 'Nesprávna hodnota.',
  ext: (field: string) => 'Nie je platný súbor.',
  image: (field: string) => 'Nie je obrázok.',
  oneOf: (field: string) => 'Pole má nesprávnu hodnotu.',
  length: (field: string, { length }: { length: number }) => `Požadovaný počet znakov: ${length}.`,
  max: (field: string, { length }: { length: number }) => `Pole môže obsahovať najviac ${length} znakov.`,
  max_value: (field: string, { max }: { max: number }) => `Hodnota pola musí byť maximálne ${max}.`,
  mimes: (field: string) => 'Nesprávny typ súboru.',
  min: (field: string, { length }: { length: number }) => `Pole musí obsahovať minimálne ${length} ${length < 4 ? 'znaky' : 'znakov'}.`,
  min_value: (field: string, { min }: { min: number }) => `Hodnota pola musí byť minimálne ${min}.`,
  numeric: (field: string) => 'Pole môže obsahovať len číslice.',
  regex: (field: string) => 'Formát položky je nesprávny.',
  required: (field: string) => 'Pole je povinné.',
  required_if: (field: string) => 'Pole je povinné.',
  size: (field: string, { size }: { size: number }) => `Hodnota pola musí byť menej ako ${FORMAT_FILE_SIZE(size)}.`
}
