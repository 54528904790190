import { FORMAT_FILE_SIZE } from '@/utils'

export default {
  'address.add.label': 'Přidat adresu',
  'address.city.label': 'Město',
  'address.city.placeholder': 'Zadej město',
  'address.country.label': 'Stát',
  'address.house-number.label': 'Číslo domu',
  'address.house-number.placeholder': 'Zadej číslo domu',
  'address.label': 'Adresa',
  'address.street-name.label': 'Ulice',
  'address.street-name.placeholder': 'Zadej ulici',
  'address.zip-code.label': 'PSČ',
  'address.zip-code.placeholder': 'Zadej směrovací číslo',
  'addresses.label': 'Adresy',
  'auditable.tooltip.created': 'Vytvořil: {0}, {1}',
  'auditable.tooltip.updated': 'Vytvořil: {0}, {1}<br>Upravil: {2}, {3}',
  'bank-account.bank-id.label': 'Kód banky',
  'bank-account.bank-id.placeholder': 'Zadej kód banky',
  'bank-account.bic.label': 'SWIFT/BIC',
  'bank-account.bic.placeholder': 'Zadej SWIFT/BIC',
  'bank-account.create.label': 'Vytvořit bankovní spojení',
  'bank-account.iban.label': 'IBAN',
  'bank-account.iban.placeholder': 'Zadej IBAN',
  'bank-account.label': 'Bankovní účet',
  'bank-account.label.short': 'Účet',
  'bank-account.name.label': 'Banka',
  'bank-account.name.placeholder': 'Zadej název banky',
  'bank-account.number.label': 'Číslo účtu',
  'bank-account.number.placeholder': 'Zadej číslo účtu',
  'bank-account.update.title': 'Upravit bankovní spojení',
  'bank-accounts.label': 'Bankovní účty',
  'bank-tx-import.upload.success': 'Import plateb proběhl úspěšně.',
  'cash-box.create.label': 'Vytvořit kasu',
  'cash-box.edit.label': 'Upravit kasu',
  'cash-box.label': 'Kasa',
  'cash-boxes.label': 'Kasy',
  'code-list.create.label': 'Vytvoření položky',
  'code-list.edit.label': 'Editace položky',
  'common.add': 'Přidat',
  'common.amount': 'Množství',
  'common.amount.money': 'Částka',
  'common.amount.placeholder': 'Zadej sumu',
  'common.cancel': 'Zrušit',
  'common.cash': 'Hotovost',
  'common.category': 'Kategorie',
  'common.category.multiple': 'Víc kategorií',
  'common.cheque': 'Šeky',
  'common.code': 'Kód',
  'common.color': 'Barva',
  'common.data-source': 'Zdroj dat',
  'common.data.detailed.summary': 'Detailní přehled dat',
  'common.data.not-records': 'Žádný záznam neexistuje.',
  'common.data.too-many-records': 'Velké množství dát, upřesnite vyhledání.',
  'common.date': 'Datum',
  'common.date-time': 'Datum a čas',
  'common.date.from': 'Datum od',
  'common.date.to': 'Datum do',
  'common.days': 'dní',
  'common.default': 'Výchozí',
  'common.delete': 'Smazat',
  'common.delta': 'Změna',
  'common.denied': 'Zakázané',
  'common.detail': 'Detail',
  'common.difference': 'Rozdíl',
  'common.edit': 'Editovat',
  'common.email': 'E-mail',
  'common.email.placeholder': 'Zadej e-mail',
  'common.enabled': 'Povolená',
  'common.enabled.2': 'Povolené',
  'common.error': 'Chyba',
  'common.export': 'Exportovat',
  'common.fetch': 'Načíst',
  'common.file': 'Soubor',
  'common.filter': 'Filtrovat',
  'common.filter.cancel': 'Zrušit filtr',
  'common.firstname': 'Jméno',
  'common.firstname.placeholder': 'Zadej jméno',
  'common.from': 'Od',
  'common.grouping': 'Seskupení',
  'common.inactive.label': 'Vyřazené',
  'common.inactive.show': 'Zobrazit neaktivní',
  'common.income': 'Příjem',
  'common.income.type': 'Typ příjmu',
  'common.index': 'Index',
  'common.item': 'Položka',
  'common.item.inline': 'Položka:',
  'common.item.add': 'Přidat položku',
  'common.loading': 'Načítání dat',
  'common.month.current': 'Aktuální měsíc',
  'common.month.previous': 'Minulý měsíc',
  'common.move.down': 'Presunout dolů',
  'common.move.up': 'Presunout nahoru',
  'common.movement': 'Pohyb',
  'common.name': 'Název',
  'common.name.placeholder': 'Zadej název',
  'common.no': 'Ne',
  'common.not-defined': 'Nezařazené',
  'common.not-valid': '!!! Neplatná položka',
  'common.note': 'Poznámka',
  'common.note.short': 'Pozn.',
  'common.number': 'Číslo',
  'common.options.empty': 'Nebyla nalezena žádná položka.',
  'common.outcome': 'Výdej',
  'common.overview': 'Přehled',
  'common.payment': 'Platba',
  'common.payment.card': 'Kartou',
  'common.payments.card': 'Platby kartou',
  'common.period': 'Od - Do',
  'common.price': 'Cena',
  'common.price.total': 'Cena celkem',
  'common.price.total.with.vat': 'Cena celkem s DPH',
  'common.price.total.without.vat': 'Cena celkem bez DPH',
  'common.price.unit': 'Cena / MJ',
  'common.price.without.vat': 'Celkem bez DPH',
  'common.price.without.vat.short': 'Σ bez DPH',
  'common.print': 'Tisknout',
  'common.print.documents': 'Vytisknout dokumenty',
  'common.print.setting': 'Nastavení před tiskem',
  'common.production': 'Výroba',
  'common.purchase': 'Nákup',
  'common.quantity': 'Počet',
  'common.range.label': 'Období',
  'common.remove': 'Odstranit',
  'common.sale': 'Prodej',
  'common.save': 'Uložit',
  'common.search': 'Vyhledej',
  'common.search.label': 'Vyhledat',
  'common.search.placeholder': 'Zadej text',
  'common.show.all': 'Zobrazit všechny',
  'common.show.all.2': 'Zobrazit všechno',
  'common.show.details': 'Zobrazit detaily',
  'common.sign.date': '(datum, podpis)',
  'common.status': 'Stav',
  'common.stop': 'Zastavit',
  'common.subject': 'Subjekt',
  'common.subscriber': 'Odběratel',
  'common.summary': 'Souhrn',
  'common.supplier': 'Dodavatel',
  'common.surname': 'Přijmení',
  'common.surname.placeholder': 'Zadej přijmení',
  'common.to': 'Do',
  'common.total': 'Celkem',
  'common.total.with.vat': 'Celkem s DPH',
  'common.total.with.vat.short': 'Σ s DPH',
  'common.transfer': 'Převod',
  'common.type': 'Typ',
  'common.user.created': 'Vystavil',
  'common.valid-from.label': 'Počáteční datum',
  'common.value': 'Hodnota',
  'common.vat.share': 'Z toho DPH',
  'common.vat.total': 'Celkem DPH',
  'common.with.vat': 's DPH',
  'common.without.vat': 'bez DPH',
  'common.year': 'Rok',
  'common.yes': 'Ano',
  'company-branch.create.label': 'Založit provoz ',
  'company-branch.edit.label': 'Upravit provoz',
  'company-branch.label': 'Firma',
  'company-branch.name.label': 'Název',
  'company-branch.name.placeholder': 'Zadej název',
  'company-branches.label': 'Firmy',
  'company.address.label': '{0} (sídlo)',
  'company.address.label.short': 'Sídlo',
  'company.company-id.label': 'IČ',
  'company.company-id.placeholder': 'Zadej IČ',
  'company.create.label': 'Vytvořit firmu',
  'company.default-currency.label': 'Hlavní měna',
  'company.for-order.label': 'Používat adresu pro objednávky a dodávky',
  'company.for-order.info': 'Adresa pro objednávky a dodávky',
  'company.label': 'Firma',
  'company.sale-settings.delivery-note-enabled.label': 'Dodací list',
  'company.sale-settings.due-date-offset.label': 'Splatnost faktur',
  'company.sale-settings.edit.label': 'Upravit nastavení spolupráce',
  'company.sale-settings.payment-type.label': 'Objednávky - platby',
  'company.save.success': 'Firma byla úspěšně uložená.',
  'company.vat-id-local.label': 'DIČ (SK)',
  'company.vat-id-local.placeholder': 'Zadej DIČ (SK)',
  'company.vat-id.label': 'IČ-DPH',
  'company.vat-id.placeholder': 'Zadej IČ-DPH',
  'company.vat-payer.label': 'Plátce DPH',
  'company.vat-payer.not.label': 'Neplátce DPH',
  'company.web.label': 'Web',
  'company.web.placeholder': 'Zadej web',
  'configuration.invoice.edit.label': 'Upravit výchozí hodnoty',
  'configuration.invoice.label': 'Výchozí hodnoty',
  'configuration.invoice.logo.label': 'Logo',
  'configuration.invoice.stamp.label': 'Razítko',
  'configuration.invoice.vat.calculation-type.label': 'Výpočet DPH',
  'confirmation.button.yes': 'Potvrdit',
  'confirmation.title': 'Upozornění',
  'contact.add.label': 'Přidat kontakt',
  'contact.create.label': 'Vytvořit kontakt',
  'contact.edit.label': 'Upravit kontakt',
  'contact.label': 'Kontakt',
  'contact.name.label': 'Název',
  'contact.name.placeholder': 'Zadej název kontaktu',
  'contact.phone-number.label': 'Telefonní číslo',
  'contact.phone-number.placeholder': 'Zadej telefonní číslo',
  'contact.position.label': 'Pozice',
  'contact.position.placeholder': 'Zadej pozici',
  'contacts.label': 'Kontakty',
  'currencies.label': 'Povolené měny',
  'currency.label': 'Měna',
  'date.today': 'Dnes',
  'date.yesterday': 'Včera',
  'date.year.current': 'Tento rok',
  'date.year.last': 'Minulý rok',
  'date.month.current': 'Tento měsíc',
  'date.month.last': 'Minulý měsíc',
  'date.week.last': 'Minulý týden',
  'date.week.current': 'Tento týden',
  'delivery-note.label': 'Dodací list č. {0}',
  'delivery-note.label.short': 'Dodací list',
  'delivery-notes.label': 'Dodací listy',
  'delivery-route.map.show': 'Zobrazit trasu na mapě',
  'delivery-routes.label': 'Rozvozové trasy',
  'delivery-routes.region.label': 'Rozvozové trasy v regionech',
  'document.delete.confirmation': 'Opravdu chcete soubor odstranit?',
  'document.empty': 'Dokument nebyl vybraný.',
  'document.empty.image': 'Obrázek nebyl vybraný.',
  'document.label': 'Dokument',
  'document.upload.choose.file': 'Vyber nebo přetáhni soubor',
  'document.upload.drop-zone.label': 'Pusť soubor tady',
  'document.upload.load.file': 'Nahrát',
  'entity.delete.confirmation': 'Opravdu chcete odstranit položku?',
  'entity.delete.success': 'Položka byla úspěšně smazaná.',
  'entity.edit.error': 'Položku se nepodařilo změnit.',
  'entity.edit.success': 'Položka byla změněná.',
  'error.auth.login': 'Neplatné přihlašovací údaje.',
  'error.bank-money-movement.moneyBox.not.exist': 'Pro bankovní účet s názvem "{0}" neexistuje pokladna.',
  'error.bank-money-movement.remainingAmount.not-zero': 'Částka pohybu musí být rozdělená celá.',
  'error.bank-tx-import.account.not.found.by.iban': 'Pro importované transakce neexistuje bankovní účet.',
  'error.bank-tx-import.catm.transactionId.unknown': 'Není možné rozeznat ID transakce.',
  'error.cash-box.accept.empty': 'Kasa musí přijímat aspoň jeden způsob platby.',
  'error.company.owned.missing': 'Nastavení firmy není dokončené.',
  'error.data.integrity.violation.duplicate': 'Akci není možné vykonat. V aplikaci už existuje záznam se stejnou hodnotou.',
  'error.data.integrity.violation.referenced': 'Změnu není možné uložit. Změněný záznam je v systému navázaný v jiných záznamech.',
  'error.data.invalid': 'Neplatná data.',
  'error.inventory.date.invalid': 'Neplatné datum. Už existuje skladový pohyb s novějším datem.',
  'error.inventory.invalid.item.price': 'Některé z položek nemají pro dané období platný cenník.',
  'error.inventory.items.emtpy': 'Inventura neobsahuje žádné položky.',
  'error.invoice.dueDate.before.dateOfIssue': 'Datum splatnosti nesí být před datem vystavení.',
  'error.invoice.document.multiple.size.overflow': 'Maximální počet hromadného stahování faktur je {0}.',
  'error.invoice.pay.amount.low': 'Suma pohybu nesmí být vyšší než nezaplacený zůstatek na faktuře.',
  'error.item.dispatch.item.invalid': 'Neplatná položka.',
  'error.item.update.dispatch.item': 'Položku není možné označit za skladovou, protože obsahuje nastavení položek vyskladněných při prodeji.',
  'error.item.update.stock-item.active': 'Označení položky za skladovou není možné zrušit. Položku nejdřív musíte na skladech deaktivovat.',
  'error.item.update.dispatch.subitem': 'Položku není možné označit za neskladovou, protože je zahrnutá jako vyskladňovaná položka při prodaji u jiné neskladové položky.',
  'error.money-movement.type.not.allowed': 'Neplatný typ faktury.',
  'error.not.allowed': 'Neoprávněný přístup.',
  'error.order-statement.currency.multiple': 'Vyúčtování nesmí obsahovat objednávky různých jmen.',
  'error.order-statement.not.delivered.order': 'Vyúčtování nesmí obsahovat nedodanou objednávku.',
  'error.order-statement.orders.empty': 'Vyúčtování musí obsahovat aspoň jednu objednávku',
  'error.order.item.price.required': 'Některé z položek nemají platný cenník.',
  'error.original-password.invalid': 'Nesprávné aktuální heslo.',
  'error.price-list.item.invalid': 'Ceník není možné pro danou katalogovou položku editovat.',
  'error.priceList.currency.duplicate': 'Ceník obsahuje pro některé menu duplicitní záznamy.',
  'error.range.too.big': 'Maximální rozsah data je 1 rok.',
  'error.recipe.delete.items.not.empty': 'Nejdříve musíte smazat všechny položky receptu.',
  'error.record.notFound': 'Záznam sa nenašel.',
  'error.stock.item.update.item.invalid': 'Neplatná položka',
  'error.stock.movement.item.never.income': 'Položka "{0}" nebyla nikdy naskladněná.',
  'error.stock.movement.item.not.in.stock': 'Položka "{0}" není přidaná na sklad nebo je vyřazená.',
  'error.stock.movement.recipe.not.valid.item': 'Položku "{0}" z vybrané receptury není možné vyskladnit. Nejprve musíte tuto položku povolit pro cílový sklad.',
  'error.stock.movement.transfer.not.valid.item': 'Položku "{0}" z vybraného výdaje není možné naskladnit. Nejprve musíte tuto položku povolit pro cílový sklad.',
  'error.stock.movement.update.not.last': 'Je možné změnit jen poslední pohyb.',
  'error.stock.record.date.invalid': 'Neplatné datum. Už existuje skladový pohyb s novějším datem.',
  'error.takings.update.totalAmountCash.min': 'Hodnota celkové hotovosti nesmí být menší než hodnota hotovosti.',
  'error.upload.denied': 'Soubor se nepodařilo nahrát na server.',
  'error.upload.extension': 'Nepodporovaný typ souboru. Podporované typy jsou {0}.',
  'error.upload.network': 'Soubor se nepodařilo nahrát na server.',
  'error.upload.server': 'Soubor se nepodařilo nahrát na server.',
  'error.upload.size': 'Příliš velký soubor. Maximální velikost souboru je {0}.',
  'error.user.not.worker': 'Přihlášený užívatel není pracovník.',
  'error.work-record.date.invalid': 'Neplatné datum od - do.',
  'error.work-record.date.overlap': 'Záznam se překrývá s jiným záznamem.',
  'error.work-record.timesheet.locked': 'Výkaz práce je pro toto období uzamčený.',
  'finance.accounting-type.label': 'Účtování DPH',
  'finance.amount.card': 'Fiskální příjem - kartou',
  'finance.amount.cash': 'Fiskální příjem - hotovost',
  'finance.amount.cash.non.fiscal': 'Nefiskální příjem - hotovost',
  'finance.amount.cheque': 'Fiskální příjem - šeky',
  'finance.amount.cheque.non.fiscal': 'Nefiskální příjem - šeky',
  'finance.cash-flow.label': 'CashFlow',
  'finance.cash-flow.status.label': 'Stav cashflow',
  'finance.fiscal.label': 'Fiskální',
  'finance.income.fiscal.label': 'Fiskální příjem',
  'finance.income.label': 'Příjem',
  'finance.income.non-fiscal.label': 'Nefiskální příjem',
  'finance.income.vat': 'Příjmy DPH',
  'finance.income.without.vat': 'Příjmy bez DPH',
  'finance.incomes.label': 'Příjmy',
  'finance.label': 'Finance',
  'finance.non-fiscal.label': 'Nefiskální',
  'finance.outcome.label': 'Výdaj',
  'finance.outcome.vat': 'Výdaje DPH',
  'finance.outcome.without.vat': 'Výdaje bez DPH',
  'finance.outcomes.label': 'Výdaje',
  'finance.overview.label': 'Přehled financí',
  'finance.price.with.vat': 'Cena s DPH',
  'finance.tax.base.label': 'Základ daně',
  'finance.tax.base.label.detailed': 'Základ daně z příjmu',
  'finance.tax.base.label.short': 'Základ',
  'finance.taxable-date.label.short': 'DUZP',
  'finance.vat-rate.label': 'Sazba DPH',
  'finance.vat-rate.label.short': 'Sazba',
  'finance.vat.income.label': 'Přijaté DPH',
  'finance.vat.label': 'DPH',
  'finance.vat.outcome.label': 'Vydaná DPH',
  'finance.vat.placeholder': 'Zadej DPH',
  'footer.company': 'SmartBrains',
  'footer.copyright.label': 'Copyright © 2020',
  'group-type.month.label': 'Měsíce',
  'group-type.week.label': 'Týdny',
  'group-type.day.label': 'Dny',
  'inventory.categories.label': 'Jen vybrané kategorie',
  'inventory.create': 'Vytvořit inventuru',
  'inventory.create.label': 'Nová inventura',
  'inventory.delete.confirmation': 'Chcete opravdu smazat tuto inventuru?',
  'inventory.delete.success': 'Inventura byla úspěšně smazaná.',
  'inventory.income-movement.amount.label': 'Hodnota přebytku celkem',
  'inventory.items.label': 'Inventurní stav položek',
  'inventory.label': 'Inventura č. {0}',
  'inventory.label.short': 'Inventura',
  'inventory.movements.summary.label': 'Vyrovnání stavu skladu',
  'inventory.outcome-movement.amount.label': 'Hodnota manka celkem',
  'inventory.status.label': 'Inventurní stav',
  'invoice-items.label': 'Položky faktury',
  'invoice-series.create.label': 'Vytvořit číselnou řadu',
  'invoice-series.edit.label': 'Upravit číselnou řadu',
  'invoice-series.example.label': 'Ukázka čísla faktury / VS',
  'invoice-series.format.label': 'Formát',
  'invoice-series.index.label': 'Aktuální index',
  'invoice-series.index.placeholder': 'Index',
  'invoice-series.label': 'Číselná řada faktury',
  'invoice-series.label.plural': 'Číselné řady',
  'invoice-series.prefix.label': 'Prefix',
  'invoice-series.prefix.placeholder': 'Zadej prefix',
  'invoice.add': 'Přidat fakturu',
  'invoice.constant-symbol.label': 'Konstantní symbol',
  'invoice.constant-symbol.label.short': 'Konstantní s.',
  'invoice.create.label': 'Nová faktura',
  'invoice.data.label': 'Další nastavení',
  'invoice.date-of-issue.label': 'Datum vystavení',
  'invoice.delete.confirmation': 'Chcete opravdu smazat tuto fakturu?',
  'invoice.delete.success': 'Faktura byla úspešně smazaná.',
  'invoice.detail.label': 'Detail faktury',
  'invoice.document.multiple.name.prefix': 'Faktury',
  'invoice.document.name.prefix': 'Faktura',
  'invoice.due-date.label': 'Datum splatnosti',
  'invoice.due.date.days.label': 'Splatnost (den)',
  'invoice.due.date.label': 'Splatnost',
  'invoice.due.date.mean': 'Průměrná splatnost',
  'invoice.edit.label': 'Úprava faktury',
  'invoice.income.label': 'Přijatá faktura č. {0}',
  'invoice.label': 'Faktura',
  'invoice.label.description': 'Suma: {0}, splatnost: {1}',
  'invoice.label.generic': 'Doklad',
  'invoice.label.with.number': 'Faktura {0}',
  'invoice.number.label': 'Číslo faktury',
  'invoice.outcome.label': 'Faktura č. {0}',
  'invoice.overdue.label': 'Po splatnosti',
  'invoice.paid': 'Uhrazené',
  'invoice.partially-paid': 'Částečně uhrazené',
  'invoice.payment.label': 'Úhrady faktury',
  'invoice.reference.label': 'Reference (např. objednávka)',
  'invoice.reference.label.short': 'Reference',
  'invoice.specific-symbol.label': 'Specifický symbol',
  'invoice.specific-symbol.label.short': 'Specifický s.',
  'invoice.taxable-date.label': 'Datum zdan. plnění',
  'invoice.total-price.label': 'Celkem k zaplacení',
  'invoice.transferred-vat.amount.label': 'Vyčíslení DPH',
  'invoice.transferred-vat.label': 'Přenos daňové povinnosti',
  'invoice.unpaid.amount.label': 'Neuhrazené',
  'invoice.variable-symbol.label': 'Variabilní symbol',
  'invoice.variable-symbol.label.short': 'Variabilní s.',
  'invoices.income.label': 'Přijaté faktury',
  'invoices.label': 'Faktury',
  'invoices.newest.label': 'Nejnovější FA',
  'invoices.outcome.label': 'Vystavené faktury',
  'invoices.overview.label': 'Přehled fakturace',
  'invoicing.label': 'Fakturace',
  'item-batch.label': 'Šarža',
  'item-batch.name.label': 'Číslo šarže',
  'item-batches.label': 'Šarže položky',
  'item-batches.placeholder': 'Šarže položky',
  'item-categories.label': 'Kategorie položek',
  'item.batch-evidence.label': 'Evidovat šarže',
  'item.card.label': 'Karta položky katalogu',
  'item.create.label': 'Vytvořit katalogovou položku',
  'item.description.label': 'Název pro tisk',
  'item.dispatch.amount.label': 'Vyskladňované množství na MJ',
  'item.dispatch.create.label': 'Přidať položku pro vyskladnění',
  'item.dispatch.edit.label': 'Upraviť položku pro vyskladnění',
  'item.dispatch.label': 'Položky vyskladňované při prodeji',
  'item.edit.label': 'Upravit katalogovou položku',
  'item.filter.label': 'Filter položek',
  'item.for-purchase.label': 'Na prodej',
  'item.for-purchase.unused': 'Položka nemá platný ceník',
  'item.for-stock.label': 'Skladová položka',
  'item.for-stock.label.short': 'Skladová',
  'item.for-stock.unused': 'Položka není přiřazena na žádný sklad',
  'item.origin.label': 'Původ',
  'item.price-list.none': 'Položka je na prodej, ale nemá žádný platný ceník. Přidat ceník můžete na záložce Prodej.',
  'item.scale.label': 'Počet desetinných míst',
  'item.scale.zero.label': 'Nedělitelná',
  'item.stock-item.none': 'Položka je skladová, ale není přiřazená na žádný sklad. Přiřadit ji můžete na záložce Sklad.',
  'item.type.label': 'Typ položky',
  'items.label': 'Katalog položek',
  'login.submit': 'Přihlásit',
  'money-box.balance.delta.label': 'Změna zůstatku za období',
  'money-box.balance.label': 'Aktuální zůstatek',
  'money-box.create.label': 'Vytvořit pokladnu nebo účet',
  'money-box.edit.label': 'Upravit pokladnu nebo účet',
  'money-box.initial-amount.label': 'Počáteční stav účtu',
  'money-box.label': 'Pokladna',
  'money-boxes.label': 'Pokladny a účty',
  'money-boxes.label.short': 'Pokladny',
  'money-boxes.status.label': 'Stav pokladny',
  'money-movement-categories.income.label': 'Přijmové kategorie pohybů',
  'money-movement-categories.outcome.label': 'Výdejové kategorie pohybů',
  'money-movement.add': 'Pridat pohyb',
  'money-movement.amount.tooltip': '<div><span class="float-left">Hotovost:</span><span class="ml-2 float-right">{0}</span></div><div class="clearfix"><span class="float-left">Šeky:</span><span class="ml-2 float-right">{1}</span></div>',
  'money-movement.categories.label': 'Kategorie',
  'money-movement.category.UNCATEGORIZED': 'Nezařazené',
  'money-movement.category.label': 'Kategorie',
  'money-movement.create.label': 'Vytvořit pohyb',
  'money-movement.edit.label': 'Upravit pohyb',
  'money-movement.filter.label': 'Filtr pohybů',
  'money-movement.import.label': 'Import bankovních výpisů',
  'money-movement.import.label.short': 'Import pohybů',
  'money-movement.import.number': 'Počet importovaných/ignorovaných',
  'money-movement.split.label': 'Rozdělit pohyb',
  'money-movement.split.note': 'Rozdělený pohyb',
  'money-movement.split.remaining.label': 'Zbývá rozdělit',
  'money-movement.split.success': 'Pohyb byl úspěšně rozdělený.',
  'money-movement.type.label': 'Typ pohybu',
  'money-movements.label': 'Pohyby',
  'money-movements.summary.by.category.label': 'Souhrn po kategoriích',
  'money-movements.summary.label': 'Pohyby na pokladnách a účtech',
  'money-movements.total.fiscal.label': 'Celkem - fiskálně',
  'money-movements.total.not.fiscal.label': 'Celkem - nefiskálně',
  'navbar.logout': 'Odhlásit',
  'navbar.password.change': 'Změnit heslo',
  'order-item-template.create.label': 'Přidat položku šablony objednávky',
  'order-item-template.edit.label': 'Upravit položku šablony objednávky',
  'order-item-template.label': 'Šablona pravidelné objednávky',
  'order-statement.create.label': 'Vytvořit vyúčtování',
  'order-statement.date.label': 'Datum vyúčtování',
  'order-statement.delete.confirmation': 'Opravdu chcete odstranit vyúčtování "{0}"?',
  'order-statement.delete.success': 'Vyúčtování bylo úspěšně smazané.',
  'order-statement.detail.label': 'Detail vyúčtování',
  'order-statement.label': 'Vyúčtování dodávek',
  'order-statement.label.short': 'Vyúčtování',
  'order-statement.label.with.number': 'Vyúčtování č. {0}',
  'order-statement.list.filter.empty': 'Pro zobrazení objednávek musí být vybraný odběratel a měna.',
  'order-statement.new.label': 'Nové vyúčtování',
  'order-statement.orders.label': 'Dodávky zahrnuté do vyúčtování',
  'order-statement.total.invoiced.label': 'Fakturované',
  'order-statement.total.price.label': 'Hodnota celkem',
  'order-statements.label.short': 'Vyúčtování',
  'order-statements.overview.label': 'Přehled vyúčtování',
  'order.create.label': 'Vytvořit objednávku',
  'order.delete.confirmation': 'Chcete opravdu smazat objednávku?',
  'order.delete.success': 'Objednávka byla úspěšně smazaná.',
  'order.deliver.all.confirmation': 'Opravdu chcete označit všechny objednávky za dodané v plné hodnotě objednávky?',
  'order.delivered': 'Dodaná',
  'order.delivered.all': 'Všechno dodané',
  'order.delivered.date.label': 'Datum dodání',
  'order.delivered.label': 'Dodávka č. {0}',
  'order.deliveries.label': 'Seznam dodávek',
  'order.delivery.schedule.label': 'Rozpis dodávek',
  'order.delivery.status.label': 'Stav dodávky',
  'order.item.label': 'Položka',
  'order.items.label': 'Položky objednávky',
  'order.label': 'Objednávka',
  'order.label.short': 'Obj.č.',
  'order.label.with.number': 'Objednávka č. {0}',
  'order.list.filter.empty': 'Pro zobrazení objednávek musí být vybrané datum a provozovna.',
  'order.new.label': 'Nová objednávka',
  'order.not-billed.number': 'Nevyúčtování: {0}',
  'order.ordered.label': 'Objednané',
  'order.returned.label': 'Vrácené',
  'order.statements.not.billed.label': 'Nevyúčtované objednávky',
  'order.subscriber-order-number.label': 'Číslo objednávky partnera',
  'order.total-without-vat.label': 'Hodnota',
  'order.undelivered.number': 'Počet nedodaných: {0}',
  'orders.delivered': 'Dodané',
  'orders.delivered.label': 'Dodávky',
  'orders.delivered.overview.label': 'Přehled dodávek',
  'orders.delivered.summary.by.product.label': 'Souhrn dodávek - produkty',
  'orders.deliveries.label': 'Objednávky a dodávky',
  'orders.label': 'Objednávky',
  'orders.period.label': 'Období objednávek',
  'orders.summary.by.item.label': 'Souhrn po položkách',
  'orders.summary.item.label': 'Souhrn dodávek - položky',
  'orders.summary.partner.label': 'Sourhn po partnerech',
  'orders.summary.payment.label': 'Souhrn dodávek - platba',
  'orders.summary.period.label': 'Souhrn dodávek za období',
  'orders.summary.returned.label': 'Souhrn vrácených po položkách',
  'orders.total.delivered.label': 'Počet dodávek',
  'partner.address.regions.label': 'Regiony adres partnerů',
  'partner.categories.label': 'Kategorie partnerů',
  'partner.category.label': 'Kategorie partnera',
  'partner.contacts.label': 'Kontakty partnerů',
  'company.edit.label': 'Upravit firmu',
  'partner.label': 'Partner',
  'partner.profile.label': 'Profil partnera',
  'partners.label': 'Partneři',
  'payment-method.label': 'Způsob platby',
  'payment-method.unknown': 'Bez typu platby',
  'payment-type.statement-enabled.label': 'Vyúčtování a fakturace',
  'payment-types.label': 'Typ platby od partnerů',
  'price-list.create.label': 'Vytvořit ceník',
  'price-list.edit.label': 'Upravit ceník',
  'price-list.individual.label': 'Individuální ceník',
  'price-list.item.label': 'Produkt',
  'price-list.label': 'Od {0}',
  'recipe-item.create.label': 'Přidat položku receptury',
  'recipe-item.edit.label': 'Upravit položku receptury',
  'recipe-item.label': 'Výrobní položka',
  'recipe-items.label': 'Položky receptury',
  'recipe.amount.label': 'Množství na výrobní dávku',
  'recipe.batch-size.label': 'Velikost výrobní dávky',
  'recipe.edit.label': 'Upravit výrobní dávku',
  'record.create.label': 'Vytvořit záznam',
  'record.delete.confirmation': 'Opravdu chcete odstranit záznam?',
  'record.delete.success': 'Záznam byl úspěšně smazaný.',
  'record.label': 'Záznam',
  'record.update.label': 'Upravit záznam',
  'records.label': 'Záznamy',
  'records.overview.label': 'Přehled záznamů',
  'region.label': 'Region',
  'region.unknown': 'Bez zařazení',
  'regions.label': 'Regiony',
  'regular-sale.label': 'Pravidelný prodej',
  'regular-sale.orders.label': 'Denní objednávky',
  'setting.company.label': 'Nastavení firmy',
  'setting.finance.label': 'Nastavení financí',
  'setting.invoice.label': 'Nastavení fakturace',
  'setting.label': 'Nastavení',
  'setting.permission.label': 'Nastavení práv uživatele',
  'setting.regular-sale.label': 'Nastavení pravidelného prodeje',
  'setting.sale.label': 'Nastavení prodeje',
  'setting.stock.label': 'Nastavení skladu a položek',
  'setting.worker.label': 'Nastavení pracovníků',
  'setting.working-activity.label': 'Nastavení pracovních činností',
  'settings.label': 'Nastavení',
  'stock-item.amount.label': 'Stav položky na skladech',
  'stock-item.amount.label.short': 'Zásoba',
  'stock-item.card.label': 'Skladová karta položky',
  'stock-item.delta.label': 'Změna stavu zásob',
  'stock-item.min-amount.detailed.label': 'Minimální zásoba: {0} {1}',
  'stock-item.min-amount.label': 'Minimální zásoba',
  'stock-item.min-amount.label.short': 'Min. zásoba',
  'stock-item.missing.label': 'Chybějící zásoba',
  'stock-item.status.label': 'Aktuální stav na skladě',
  'stock-items.label': 'Stav zásob',
  'stock-items.under.limit.label': 'Chybějící zásoba položek',
  'stock-movement.create.label': 'Přidat položku na sklad',
  'stock-movement.date.label': 'Datum vystavení',
  'stock-movement.edit.label': 'Upravit položku na skladě',
  'stock-movement.filter.label': 'Filtr pohybů',
  'stock-movement.income.amount.label': 'Hodnota příjmu',
  'stock-movement.income.create': 'Vytvořit příjem',
  'stock-movement.income.delete.confirmation': 'Chcete opravdu smazat tento příjem na skladě?',
  'stock-movement.income.delete.success': 'Příjem na skladě byl úspěšně smazaný.',
  'stock-movement.income.items.label': 'Položky příjmu',
  'stock-movement.income.label': 'Příjem na sklad č. {0}',
  'stock-movement.income.label.short': 'Příjem na sklad',
  'stock-movement.income.label.without.number': 'Příjem na sklad č.',
  'stock-movement.income.new.label': 'Nový příjem na sklad',
  'stock-movement.income.summary.label': 'Souhrn příjmu na sklad',
  'stock-movement.origin-stock.label': 'Původní sklad',
  'stock-movement.outcome.amount.label': 'Hodnota výdeje',
  'stock-movement.outcome.create': 'Vytvořit výdej',
  'stock-movement.outcome.delete.confirmation': 'Chcete opravdu smazat tento výdej ze skladu?',
  'stock-movement.outcome.delete.success': 'Výdej ze skladu byl úspěšně smazaný.',
  'stock-movement.outcome.items.add.by.recipe': 'Přidat položky podle výrobní receptury',
  'stock-movement.outcome.items.label': 'Položky výdeje',
  'stock-movement.outcome.label': 'Výdej ze skladu č. {0}',
  'stock-movement.outcome.label.short': 'Výdej ze skladu',
  'stock-movement.outcome.label.without.number': 'Výdej ze skladu č.',
  'stock-movement.outcome.new.label': 'Nový výdej ze skladu',
  'stock-movement.outcome.note.delivery': 'Dodávka dne {0}.',
  'stock-movement.outcome.summary.label': 'Souhrn výdeje ze skladu',
  'stock-movement.outcome.type.label': 'Typ výdeje',
  'stock-movement.target-stock.label': 'Cílový sklad',
  'stock-movement.transfer.label': 'Přeskladnění',
  'stock-movements.label': 'Pohyby na skladě',
  'stock-movements.label.2': 'Skladové pohyby',
  'stock.create.label': 'Vytvořit sklad',
  'stock.currency.label': 'Měna pohybů',
  'stock.edit.label': 'Upravit sklad',
  'stock.expedition.update.success': 'Výdejový sklad byl změněný.',
  'stock.for-expedition.label': 'Výdejový sklad pro dodávky',
  'stock.for-expedition.label.short': 'Výdejový sklad',
  'stock.for-expedition.setting.label': 'Nastavení výdejového skladu pro dodávky',
  'stock.label': 'Sklad',
  'stock.overview.label': 'Přehled skladů',
  'stocks.label': 'Sklady',
  'takings.create.label': 'Vytvořit tržbu',
  'takings.daily.label': 'Denní tržba kasy',
  'takings.daily.label.short': 'Denní tržba',
  'takings.edit.label': 'Upravit tržbu',
  'takings.include.non-fiscal': 'včetně nefiskálních příjmů',
  'takings.overview.label': 'Přehled tržeb',
  'takings.total.daily.label': 'Denní tržba celkem',
  'takings.total.fiscal.label': 'Uzávěrka celkem',
  'takings.total.fiscal.without.card.label': 'Hotové spolu',
  'takings.total.in.cash-box.label': 'Tržba v kase celkem',
  'takings.total.label': 'Tržba celkem',
  'takings.total.without.card.label': 'Tržba v kase',
  'takings.transfer': 'Převod tržby na pokladnu',
  'takings.transfer.money-box.label': 'Cílová hotovostní pokladna',
  'takings.transfer.success': 'Tržba byla provedená na pokladnu "{0}".',
  'takings.transfer.total.label': 'Převod celkem',
  'timesheet.lock': 'Uzamknout výkaz',
  'timesheet.locked': 'Výkaz je uzamčený',
  'timesheet.unlock': 'Odemknout výkaz',
  'timesheet.unlocked': 'Výkaz je odemknutý',
  'unit.label': 'MJ',
  'units.label': 'Měrné jednotky',
  'user.create.label': 'Vytvořit uživatele',
  'user.edit.label': 'Upravit uživatele',
  'user.label': 'Uživatel',
  'user.last-logged-in.label': 'Poslední přihlášení',
  'user.password.change.success': 'Heslo bylo změněno.',
  'user.password.change.title': 'Změna hesla',
  'user.password.confirm.label': 'Nové heslo pro kontrolu',
  'user.password.confirm.placeholder': 'Zadej nové heslo znovu',
  'user.password.current.label': 'Aktuální heslo',
  'user.password.current.placeholder': 'Zadej aktuální heslo',
  'user.password.label': 'Heslo',
  'user.password.new.label': 'Nové heslo',
  'user.password.new.placeholder': 'Zadej nové heslo',
  'user.password.placeholder': 'Zadej svoje heslo',
  'user.password.placeholder.short': 'Zadej heslo',
  'user.password.set': 'Nastavit heslo',
  'user.username.email.label': 'E-mail / login',
  'user.username.label': 'Přihlašovací jméno',
  'user.username.placeholder': 'Zadej svoje přihlašovací jméno',
  'users.label': 'Uživatelé a práva',
  'value.accounting-type.label.FISCAL': 'Fiskální',
  'value.accounting-type.label.INTERNAL': 'Převod',
  'value.accounting-type.label.NON_FISCAL': 'Nefiskální',
  'value.boolean-type.label.NO': 'Ne',
  'value.boolean-type.label.YES': 'Ano',
  'value.country.label.CZE': 'Česká republika',
  'value.country.label.SVK': 'Slovenská republika',
  'value.currency.description.CZK': 'Česká koruna [Kč]',
  'value.currency.description.EUR': 'Euro [€]',
  'value.currency.label.CZK': 'Kč',
  'value.currency.label.EUR': '€',
  'value.income-stock-movement-type.label.INVENTORY_SURPLUS': 'Inventurní přebytek',
  'value.income-stock-movement-type.label.PRODUCTION': 'Výroba',
  'value.income-stock-movement-type.label.PURCHASE': 'Nákup',
  'value.income-stock-movement-type.label.TRANSFER': 'Přeskladnění',
  'value.invoice-series-format.label.PREFIX_YYYY_NNNN': 'PREFIX + ROK + INDEX',
  'value.invoice-series-format.label.PREFIX_NNNN_YYYY': 'PREFIX + INDEX + ROK',
  'value.invoice-status.label.OUTSTANDING': 'Neuhrazené',
  'value.invoice-status.label.OVERDUE': 'Po splatnosti',
  'value.invoice-status.label.PAID': 'Uhrazené',
  'value.item-determination-type.label.SALE': 'Na predaj',
  'value.item-determination-type.label.STOCK': 'Skladová',
  'value.item-origin-type.label.PRODUCTION': 'Výroba',
  'value.item-origin-type.label.PURCHASE': 'Nákup',
  'value.item-type.label.PRODUCT': 'Hmotná',
  'value.item-type.label.SERVICE': 'Služba',
  'value.money-box-type.label.BANK': 'Účet v bance',
  'value.money-box-type.label.CASH': 'Hotovostní pokladna',
  'value.money-movement-type.label.INCOME': 'Příjem',
  'value.money-movement-type.label.OUTCOME': 'Výdej',
  'value.outcome-stock-movement-type.label.INVENTORY_SHORTAGE': 'Inventurní manko',
  'value.outcome-stock-movement-type.label.PRODUCTION': 'Výroba',
  'value.outcome-stock-movement-type.label.SALE': 'Prodej',
  'value.outcome-stock-movement-type.label.TRANSFER': 'Přeskladnění',
  'value.outcome-stock-movement-type.label.USAGE': 'Běžná spotřeba',
  'value.outcome-stock-movement-type.label.WASTE': 'Odpad',
  'value.payment-method.label.BANK_TRANSFER': 'Bankovním převodem',
  'value.payment-method.label.CASH': 'V hotovosti',
  'value.payment-method.label.CASH_ON_DELIVERY': 'Dobírka',
  'value.payment-method.label.CREDIT_CARD': 'Kartou',
  'value.permission-type.label.COMPANY_PARTNER': 'Partneři',
  'value.permission-type.label.FINANCE_MONEY_BOX': 'Pokladně',
  'value.permission-type.label.FINANCE_MOVEMENT_IMPORT': 'Import pohybů',
  'value.permission-type.label.FINANCE_OVERVIEW': 'Přehled',
  'value.permission-type.label.INVOICE': 'Faktury',
  'value.permission-type.label.REGULAR_SALE': 'Denní objednávky',
  'value.permission-type.label.SETTING_COMPANY': 'Firma',
  'value.permission-type.label.SETTING_FINANCE': 'Finance',
  'value.permission-type.label.SETTING_INVOICE': 'Fakturace',
  'value.permission-type.label.SETTING_ITEM': 'Katalog položek',
  'value.permission-type.label.SETTING_REGULAR_SALE': 'Pravidelný prodej',
  'value.permission-type.label.SETTING_SALE': 'Prodej',
  'value.permission-type.label.SETTING_STOCK': 'Sklady',
  'value.permission-type.label.SETTING_USER': 'Uživatelé a práva',
  'value.permission-type.label.SETTING_WORKER': 'Pracovníci a pracovní činnosti',
  'value.permission-type.label.STOCK': 'Stav zásob a pohyby',
  'value.permission-type.label.TAKINGS': 'Zadání tržby kasy',
  'value.permission-type.label.TAKINGS_OVERVIEW': 'Přehled tržeb',
  'value.permission-type.label.TAKINGS_TRANSFER': 'Převod tržby na pokladnu',
  'value.permission-type.label.WORK_RECORD_MANAGEMENT': 'Správa výkazů práce',
  'value.permission-type.label.WORK_RECORD_PERSONAL': 'Můj výkaz práce',
  'value.record-type.label.COMMENT': 'Poznámka',
  'value.record-type.label.EMAIL_INCOME': 'Pošta - přijatá',
  'value.record-type.label.EMAIL_OUTCOME': 'Pošta - odeslaná',
  'value.record-type.label.PERSONAL_MEETING': 'Osobní setkání',
  'value.record-type.label.PHONE_COMMUNICATION': 'Telefonát',
  'value.user-type.label.USER': 'Uživatel',
  'value.user-type.label.WORKER': 'Pracovník',
  'value.vat-calculation.label.SUM': 'Z celkové sumy',
  'value.vat-calculation.label.ITEM': 'Po položkách',
  'work-record.create.label': 'Vytvořit výkaz práce',
  'work-record.duration.label': 'Celkem vykázáno',
  'work-record.edit.label': 'Upravit výkaz práce',
  'work-record.label': 'Výkaz práce',
  'work-record.overview.label': 'Přehled výkazu práce',
  'work-record.overview.records.label': 'Přehled záznamů',
  'work-record.personal.label': 'Můj přehled výkazu práce',
  'work-record.personal.label.short': 'Můj výkaz práce',
  'work-record.price.label': 'Cena práce',
  'work-record.price.total.label': 'Cena práce celkem',
  'work-record.summary.period.label': 'Souhrn za období',
  'work-record.summary.period.label.short': 'Souhrn za měsíc',
  'work-record.summary.worker.label': 'Výkaz pracovníků',
  'worker.add': 'Přidat pracovníka',
  'worker.contract.label': 'Smluvní vztah',
  'worker.contract.placeholder': 'Zadej smluvní vztah',
  'worker.edit.label': 'Úprava pracovníka',
  'worker.label': 'Pracovník',
  'worker.new.label': 'Nový pracovník',
  'worker.user.label': 'Je uživatelem',
  'workers.label': 'Pracovníci',
  'working-activities.label': 'Pracovní činnosti',
  'working-activity.add': 'Přidat činnost',
  'working-activity.create.label': 'Nová pracovní činnost',
  'working-activity.edit.label': 'Úprava pracovní činnosti',
  'working-activity.label': 'Pracovní činnost',
  'working-activity.new.label': 'Nová činnost',
  'working-activity.price.label': 'Cena / hod.'
}

export const validations = {
  alpha: (field: string) => 'Pole může obsahovat jen písmena.',
  alpha_dash: (field: string) => 'Pole může obsahovat jen písmena, číslice, tečky a podtržítka.',
  alpha_num: (field: string) => 'Pole může obsahovat jen písmena a číslice.',
  alpha_spaces: (field: string) => 'Pole může obsahovat jen písmena, číslice a mezery.',
  between: (field: string, { min, max }: { min: number, max: number }) => `Hodnota pole musí být mezi ${min} a ${max}.`,
  confirmed: (field: string) => 'Hodnota položky není stejná.',
  decimal: (field: string, { decimals }: { decimals: number }) => `Hodnota pole musí být číselná a smí obsahovat ${decimals} desatinná místa.`,
  digits: (field: string, { length }: { length: number }) => `Pole musí obsahovat ${length} ${length < 5 ? 'čísla' : 'čísel'}.`,
  dimensions: (field: string, { width, height }: {width: number, height: number}) => `Obrázek musí mít ${width} x ${height} pixlov.`,
  email: (field: string) => 'Pole musí obsahovat správnou e-mailovou adresu.',
  excluded: (field: string) => 'Nesprávná hodnota.',
  ext: (field: string) => 'Není platný soubor.',
  image: (field: string) => 'Není obrázek.',
  oneOf: (field: string) => 'Pole má nesprávnou hodnotu.',
  length: (field: string, { length }: { length: number }) => `Požadovaný počet znaků: ${length}.`,
  max: (field: string, { length }: { length: number }) => `Pole může obsahovat najvíc ${length} znaků.`,
  max_value: (field: string, { max }: { max: number }) => `Hodnota pole musí být maximálně ${max}.`,
  mimes: (field: string) => 'Nesprávny typ souboru.',
  min: (field: string, { length }: { length: number }) => `Pole musí obsahovat minimálně ${length} ${length < 4 ? 'znaky' : 'znaků'}.`,
  min_value: (field: string, { min }: { min: number }) => `Hodnota pole musí být minimálně ${min}.`,
  numeric: (field: string) => 'Pole může obsahovat jen číslice.',
  regex: (field: string) => 'Formát položky je nesprávný.',
  required: (field: string) => 'Pole je povinné.',
  required_if: (field: string) => 'Pole je povinné.',
  size: (field: string, { size }: { size: number }) => `Hodnota pole musí být méně jak ${FORMAT_FILE_SIZE(size)}.`
}
